var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"formObserver"},[(_vm.$wait.is('loading-on-get-partnerskeys-setting-by-id'))?_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('loading')],1)],1):_c('b-form-row',{attrs:{"autocomplete":"off"}},[_c('b-col',{attrs:{"cols":"12"}},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',{staticClass:"font-weight-bolder"},[_vm._v(_vm._s(_vm.$t('User Name')))]),_c('validation-provider',{attrs:{"name":"User Name","rules":"required|min:1|max:16"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{ref:"input-username",attrs:{"state":errors.length > 0 ? false : null,"autocomplete":"off","disabled":!_vm.isFormCreateNewPartner},model:{value:(_vm.formData.username),callback:function ($$v) {_vm.$set(_vm.formData, "username", $$v)},expression:"formData.username"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',{staticClass:"font-weight-bolder"},[_vm._v(_vm._s(_vm.$t('Contact')))]),_c('validation-provider',{attrs:{"name":"Contact","rules":"min:1|max:16"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":_vm.$t('Contact')},model:{value:(_vm.formData.contact),callback:function ($$v) {_vm.$set(_vm.formData, "contact", $$v)},expression:"formData.contact"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-row',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isFormCreateNewPartner),expression:"!isFormCreateNewPartner"}]},[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',{staticClass:"font-weight-bolder"},[_vm._v(_vm._s(_vm.$t('ApiKey')))]),_c('validation-provider',{attrs:{"name":"ApiKey","rules":"min:1|max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":_vm.$t('ApiKey'),"disabled":""},model:{value:(_vm.formData.apiKey),callback:function ($$v) {_vm.$set(_vm.formData, "apiKey", $$v)},expression:"formData.apiKey"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6","align-self":"center"}},[_c('b-button',{staticClass:"btn-icon",attrs:{"disabled":_vm.$wait.is('loading-on-save'),"variant":"outline-primary"},on:{"click":_vm.onGenerateNewApiKey}},[_c('feather-icon',{attrs:{"icon":"RefreshCcwIcon"}})],1)],1)],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-checkbox',{staticClass:"custom-control-success",attrs:{"switch":""},model:{value:(_vm.formData.active),callback:function ($$v) {_vm.$set(_vm.formData, "active", $$v)},expression:"formData.active"}},[_c('span',{staticClass:"h5 font-weight-bolder"},[_vm._v(_vm._s(_vm.$t('Suspend / Active')))])])],1)],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-row',[_c('b-col',{staticClass:"bg-primary",attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex flex-row justify-content-center align-items-center"},[_c('div',{staticClass:"card-header text-white font-weight-bold h4 "},[_vm._v(" "+_vm._s(_vm.$t('IP Address'))+" ")])])]),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-tags',{staticClass:"mb-2 mt-2",attrs:{"tag-variant":"primary","placeholder":"Enter a new IP address and press Enter","state":_vm.tag_ips_state,"tag-validator":_vm.validateTag},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var tags = ref.tags;
var inputId = ref.inputId;
var placeholder = ref.placeholder;
var disabled = ref.disabled;
var addTag = ref.addTag;
var removeTag = ref.removeTag;
return [_c('b-input-group',[_c('b-form-input',{ref:"input-new-ipaddress",attrs:{"id":inputId,"placeholder":placeholder,"disabled":disabled},on:{"input":_vm.onChangeInputNewIpAddress,"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.addTagIpaddress(_vm.newIpAddress)}},model:{value:(_vm.newIpAddress),callback:function ($$v) {_vm.newIpAddress=$$v},expression:"newIpAddress"}}),_c('b-input-group-append',[_c('b-button',{attrs:{"disabled":disabled,"variant":"primary"},on:{"click":function($event){return _vm.addTagIpaddress(_vm.newIpAddress)}}},[_vm._v(" Add ")])],1)],1),_c('b-form-invalid-feedback',{attrs:{"state":_vm.tag_ips_state}},[_vm._v(" "+_vm._s(_vm.errorType === 'invalid' ? ("Invalid IP address: " + _vm.newIpAddress) : ("Duplicate IP address: " + _vm.newIpAddress))+" ")]),_c('b-card',[_c('b-card-header',[_c('h5',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.$t('IP Address List'))+" ")])]),_c('b-card-body',[(tags.length)?_c('ul',{staticClass:"list-group list-group-flush"},_vm._l((tags),function(tag){return _c('li',{key:tag,staticClass:"list-group-item d-flex justify-content-between align-items-center"},[_c('span',[_vm._v(_vm._s(tag))]),_c('b-button',{attrs:{"size":"sm","variant":"outline-danger"},on:{"click":function($event){return removeTag(tag)}}},[_vm._v(" Remove ")])],1)}),0):_vm._e()])],1)]}}]),model:{value:(_vm.formData.ips),callback:function ($$v) {_vm.$set(_vm.formData, "ips", $$v)},expression:"formData.ips"}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }