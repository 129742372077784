<template>
  <validation-observer ref="formObserver">
    <b-row v-if="$wait.is('loading-on-get-partnerskeys-setting-by-id')">
      <b-col cols="12">
        <loading />
      </b-col>
    </b-row>
    <b-form-row
      v-else
      autocomplete="off"
    >
      <b-col cols="12">
        <b-row>
          <!-- User Name Field -->
          <b-col md="6">
            <b-form-group>
              <label class="font-weight-bolder">{{ $t('User Name') }}</label>
              <validation-provider
                #default="{ errors }"
                name="User Name"
                rules="required|min:1|max:16"
              >
                <b-form-input
                  ref="input-username"
                  v-model="formData.username"
                  :state="errors.length > 0 ? false : null"
                  autocomplete="off"
                  :disabled="!isFormCreateNewPartner"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Contact Field -->
          <b-col md="6">
            <b-form-group>
              <label class="font-weight-bolder">{{ $t('Contact') }}</label>
              <validation-provider
                #default="{ errors }"
                name="Contact"
                rules="min:1|max:16"
              >
                <b-form-input
                  v-model="formData.contact"
                  :state="errors.length > 0 ? false : null"
                  :placeholder="$t('Contact')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- API Key Section -->
          <b-col cols="12">
            <b-row v-show="!isFormCreateNewPartner">
              <b-col md="6">
                <b-form-group>
                  <label class="font-weight-bolder">{{ $t('ApiKey') }}</label>
                  <validation-provider
                    #default="{ errors }"
                    name="ApiKey"
                    rules="min:1|max:100"
                  >
                    <b-form-input
                      v-model="formData.apiKey"
                      :state="errors.length > 0 ? false : null"
                      :placeholder="$t('ApiKey')"
                      disabled
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                md="6"
                align-self="center"
              >
                <b-button
                  class="btn-icon"
                  :disabled="$wait.is('loading-on-save')"
                  variant="outline-primary"
                  @click="onGenerateNewApiKey"
                >
                  <feather-icon icon="RefreshCcwIcon" />
                </b-button>
              </b-col>
            </b-row>
          </b-col>

          <!-- Active Toggle -->
          <b-col md="12">
            <b-form-checkbox
              v-model="formData.active"
              class="custom-control-success"
              switch
            >
              <span class="h5 font-weight-bolder">{{ $t('Suspend / Active') }}</span>
            </b-form-checkbox>
          </b-col>
        </b-row>
      </b-col>

      <!-- IP Address Section -->
      <b-col cols="12">
        <b-row>
          <b-col
            cols="12"
            class="bg-primary"
          >
            <div class="d-flex flex-row justify-content-center align-items-center">
              <div class="card-header text-white font-weight-bold h4 ">
                {{ $t('IP Address') }}
              </div>
            </div>
          </b-col>

          <b-col cols="12">

            <b-form-tags
              v-model="formData.ips"
              tag-variant="primary"
              class="mb-2 mt-2"
              placeholder="Enter a new IP address and press Enter"
              :state="tag_ips_state"
              :tag-validator="validateTag"
            >
              <template v-slot="{ tags, inputId, placeholder, disabled, addTag, removeTag }">
                <b-input-group>
                  <b-form-input
                    :id="inputId"
                    ref="input-new-ipaddress"
                    v-model="newIpAddress"
                    :placeholder="placeholder"
                    :disabled="disabled"
                    @input="onChangeInputNewIpAddress"
                    @keyup.enter="addTagIpaddress(newIpAddress)"
                  />
                  <b-input-group-append>
                    <b-button
                      :disabled="disabled"
                      variant="primary"
                      @click="addTagIpaddress(newIpAddress)"
                    >
                      Add
                    </b-button>
                  </b-input-group-append>
                </b-input-group>

                <b-form-invalid-feedback :state="tag_ips_state">
                  {{ errorType === 'invalid'
                    ? `Invalid IP address: ${newIpAddress}`
                    : `Duplicate IP address: ${newIpAddress}` }}
                </b-form-invalid-feedback>

                <b-card>
                  <b-card-header>
                    <h5 class="mb-0">
                      {{ $t('IP Address List') }}
                    </h5>
                  </b-card-header>
                  <b-card-body>
                    <ul
                      v-if="tags.length"
                      class="list-group list-group-flush"
                    >
                      <li
                        v-for="tag in tags"
                        :key="tag"
                        class="list-group-item d-flex justify-content-between align-items-center"
                      >
                        <span>{{ tag }}</span>
                        <b-button
                          size="sm"
                          variant="outline-danger"
                          @click="removeTag(tag)"
                        >
                          Remove
                        </b-button>
                      </li>
                    </ul>
                  </b-card-body>
                </b-card>
              </template>
            </b-form-tags>
          </b-col>
        </b-row>
      </b-col>
    </b-form-row>
  </validation-observer>
</template>

<script>
import {
  ValidationProvider, ValidationObserver,
} from 'vee-validate';
import store from '@/store';
import { required } from "@validations";

const defaultFormData = {
  username: '',
  contact: '',
  apiKey: '',
  active: true,
  ips: [],
};

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      required,

      formData: { ...defaultFormData },
      newIpAddress: '',
      tag_ips_state: null,
      errorType: null,
    };
  },
  computed: {
    userData() {
      return store.getters['auth/user'];
    },
    isFormCreateNewPartner() {
      return this.$route.name === 'partners-keys-setting-create';
    },
  },
  methods: {
    onChangeInputNewIpAddress() {
      this.errorType = null;
      this.tag_ips_state = null;
    },
    addTagIpaddress(ip) {
      if (!ip) {
        this.focusInputIpAddress()

        return
      }

      if (!this.validateTag(ip)) {
        this.focusInputIpAddress()

        return
      }

      this.formData.ips.unshift(ip);
      this.newIpAddress = '';
      this.focusInputIpAddress()
    },

    validateTag(tag) {
      const ipv4Pattern = /^(25[0-5]|2[0-4][0-9]|[0-1]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[0-1]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[0-1]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[0-1]?[0-9][0-9]?)$/;

      const isValid = ipv4Pattern.test(tag);
      const isDuplicate = this.formData.ips.includes(tag);

      if (!isValid) {
        this.errorType = 'invalid';
        this.tag_ips_state = false;
        return false;
      }

      if (isDuplicate) {
        this.errorType = 'duplicate';
        this.tag_ips_state = false;
        return false;
      }

      this.errorType = null;
      this.tag_ips_state = null;
      return true;
    },

    focusInputIpAddress() {
      this.$nextTick(() => {
        this.$refs['input-new-ipaddress'].focus();
      });
    },

    clearForm() {
      this.formData = Object.assign({}, defaultFormData)
      this.$refs['input-username'].focus()

      setTimeout(() => {
        this.$refs.formObserver.reset()
      }, 100)
    },

    async validate() {
      const valid = await this.$refs.formObserver.validate()
      return valid
    },

    getFormData() {
      return { ...this.formData, apiKey: undefined }
    },

    getDefaultFormData() {
      return Object.assign({}, defaultFormData)
    },

    setFormData({
      username,
      contact,
      apiKey,
      active,
      ips,
    }) {
      this.formData = {
        ...this.formData,
        ...typeof username !== 'undefined' && { username },
        ...typeof contact !== 'undefined' && { contact },
        ...typeof apiKey !== 'undefined' && { apiKey },
        ...typeof active !== 'undefined' && { active },
        ...typeof ips !== 'undefined' && { ips },
      }
    },

    async onSave() {
      const isValidForm = await this.$refs.formObserver.validate();
      if (isValidForm) {
        this.$emit('onSave', this.formData);
      }
    },
    onGenerateNewApiKey() {
      this.$emit('onGenerateNewApiKey');
    },
  },
};
</script>
